import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  effect,
  input,
} from '@angular/core';
import { ProgressBarComponent } from '../../../../shared/components/charts/progress-bar/progress-bar.component';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { SlicePipe } from '@angular/common';
import {
  Clo,
  Plo,
} from '../../interfaces/aol-report-course-section.interface';
import { CourseSectionsService } from '../../course-sections.service';
import { convertToCSV } from '../../../../shared/util/convert-array-to-csv.util';

@Component({
  selector: 'app-clo-achievements',
  standalone: true,
  imports: [ProgressBarComponent, TranslateModule, SlicePipe],
  templateUrl: './clo-achievements.component.html',
  styleUrl: './clo-achievements.component.scss',
})
export class CloAchievementsComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  constructor(private readonly courseSectionsService: CourseSectionsService) {
    effect(() => {
      this.selectedPloListener();
    });
  }

  @Output() onCloSelected = new EventEmitter<Clo>();

  ngOnInit() {
    this.displayedClos = this.clos();
    if (!!this.courseSectionsService.selectedPlo()) {
      this.selectedPlo = this.courseSectionsService.selectedPlo();
      this.displayedClos = this.clos()?.filter(
        (clo) => clo.plo._id === this.selectedPlo?._id
      );
    }
  }

  clos = input.required<Clo[]>();
  plos = input.required<Plo[]>();
  courseName = input.required<string>();
  sectionOrder = input.required<number>();
  displayedClos?: Clo[] = [];
  cloBenchmark = input.required<number>();
  selectedPlo: Plo | null = null;

  selectedPloListener() {
    if (!!this.courseSectionsService.selectedPlo()) {
      this.selectedPlo = this.courseSectionsService.selectedPlo();
      this.displayedClos = this.clos()?.filter(
        (clo) => clo.plo._id === this.selectedPlo?._id
      );
    }
  }

  onSelectItem(event: any) {
    const ploID = event.target.value + '';
    if (ploID === '-1') this.displayedClos = this.clos();
    else
      this.displayedClos = this.clos().filter((clo) => clo.plo._id === ploID);
  }

  navigateToCloAchievement(clo: Clo) {
    this.onCloSelected.emit(clo);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  exportAsCSV() {
    const dataToExport = (
      !!this.displayedClos ? this.displayedClos : this.clos()
    ).map((clo) => {
      return {
        CLOs: clo.name
          .split('')
          .map((char) => (char === ',' ? '' : char))
          .join(''),
        'Related PLO': clo.plo.name
          .split('')
          .map((char) => (char === ',' ? '' : char))
          .join(''),
        'CLO Benchmark': this.cloBenchmark(),
        'CLO Achievement':
          clo.achievement == -1 ? 'Unmeasured' : clo.achievement.toFixed(2),
      };
    });
    const blob = new Blob([convertToCSV(dataToExport)], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement<'a'>('a');

    a.href = url;
    a.download =
      this.courseName() +
      ' - Section (' +
      this.sectionOrder() +
      ') - ' +
      'CLO Achievement';
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }
}
